import React, {useEffect} from "react"
import { FaUser, FaStoreAlt } from 'react-icons/fa'
import {useDispatch, useSelector} from "react-redux"

import Card from "../../../../components/card/card"
import {selectCategory} from "../../../../redux/addMember/addMemberAction"
import {getCategories} from "../../../../redux/api/apiAction"
import Tab from "../../../../components/Tab/Tab"

const MemberOrNot = ({pageActive, index, onNextUnion, onNext}) => {
    const dispatch = useDispatch()
    const {categories} = useSelector(({apiReducer}) => apiReducer)

    useEffect(() => {
        dispatch(getCategories())
    }, [])

    const onClick = (nonMember = true) => {
        if(nonMember)
            dispatch(selectCategory(categories, "Delegates group"))
                .then(() => onNext())
        else
        dispatch(selectCategory(categories,  "Union Member group"))
            .then(() => onNextUnion())
    }

    return (
        <Tab pageActive={pageActive} index={index}>
            <div className={"card-list"}>
                <Card title={"Non member"} icon={<FaUser/>} onClick={() => onClick()}/>
                <Card title={"Union member"} icon={<FaStoreAlt/>} onClick={() => onClick(false)}/>
            </div>
        </Tab>
    )
}

export default MemberOrNot
