import React from "react"
import PropTypes from "prop-types"
import {Link} from "gatsby"
import {useDispatch, useSelector} from "react-redux"

import {isDefined} from "../../../utils/data"
import {createGroup} from "../../../redux/group/groupAction"

import SRModal from "../../../components/modal/modal"

const BankTransferModal = () => {
    const dispatch  = useDispatch()
    const data      = useSelector(({groupReducer}) => groupReducer)
    const {coordinator, stripe}  = useSelector(({groupReducer}) => groupReducer)

    const registration = (payment = false) => {
        dispatch(createGroup(data, payment === true ? 2 : 2))
    }
    
    return (
        <SRModal text={"Virement"} title={"Bank transfer information"} className={"bankTransfer addMember"}>
            <p>
               Click on Save registration below to receive all the information necessary to make the bank transfer.<br/>Please note that bank transfers are payable to The Union and are due in EUR. If your bank transfer is not received by Monday October 5th 2020, another payment method will be required. Please remember, the registration fee payable is determined by when payment is received and not the date of registration submission. All bank fees are payable by the remitter/sender. As international bank transfers may incur high bank fees, we recommend registration fees are paid by credit card to avoid additional fees for the payer. Please include your name, order number and WCLH 2020 on the bank transfer object, and deduct all bank charges at your end.
            </p>
	<div className={"row"}>	
            <Link to={"confirmation-record"}>
                <button className={"btn btn-warning mx-auto"} onClick={() => registration(2)}>Save registration</button>
            </Link>
	</div>
        </SRModal>
    )
}

export default BankTransferModal
