import React from "react"
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from "react-redux"

import {onChange} from "../../redux/group/groupAction"
import CheckboxInput from "../../components/form/checkboxInput"
import {validTab} from "../../redux/tabs/groupTabsAction"
import AlertCategory from "./alertCategory/alertCategory"

const YourData = ({prevTab, nextTab}) => {
    const dispatch = useDispatch()
    const {information} = useSelector(({groupReducer}) => groupReducer)

    const onClickNext = (e) => {
        e.preventDefault()

        if(information.group_cgv.value === true || information.group_cgv.value === "1") {
            dispatch(validTab("summaryTab"))
            nextTab()
        }
    }

    return (
        <div className={"content yourData col-10 mx-auto txt-left"}>
                    <AlertCategory/>

            <p className={"title"}>Comment utilisons nous vos données ?</p>

            <p className={"small txt-left"}>
                The Union will use the personal data from this form only in direct relation to your attendance at the 75 COEC (such as, for registration, programme updates and the mobile app). We will not share your data for any commercial reason.
            </p>
            <p className={"small txt-left"}>
                Please note: During the conference, exhibitors may ask if they can scan your badge. If your badge is scanned, certain details of your registration profile will be shared with the exhibitor. Be aware that by allowing your badge to be scanned you consent to sharing this information.
            </p>
            <p className={"small txt-left"}>
                By entering the event premises, delegates give their permission to be photographed or recorded by official photographers, videographers and attending media of the 75 COEC. Delegates consent to their images and video being reproduced for subsequent use in the media, webcasts, internal and external promotional purposes, advertising and inclusion on websites and social media. Images are shared publicly with open access for use.
            </p>
            <p className={"small txt-left"}>
                This is in line with COEC's <a href="" target="_blank">Privacy Policy</a>. You can change your details or this consent at any time by emailing <a href={"mailto:"}>privacy@coec.org</a>.
            </p>

            <CheckboxInput placeholder={"I understand this is how The Union will use my data"}
                           onChange={e => dispatch(onChange(e, true))}
                           name={"group_cgv"}
                           value={information.group_cgv.value}/>


            <div className={"content-footer"}>
                <button className={"sr-btn-next btn btn-secondary"} onClick={prevTab}>Précédent</button>
                <button className={"sr-btn-next btn btn-info"} disabled={information.group_cgv.value === false || information.group_cgv.value === "0"} onClick={onClickNext}>Suivant</button>
            </div>
        </div>
    )
}

YourData.propTypes = {
    prevTab: PropTypes.func.isRequired,
    nextTab: PropTypes.func.isRequired,
}

export default YourData
