import React from "react"
import PropTypes from "prop-types"
import {Link} from "gatsby"
import {useDispatch, useSelector} from "react-redux"

import BankTransferModal from "./bankTransferModal"
import CreditCardModal from "./creditCardModal"
import RefundModal from "./refundModal"
import {isDefined} from "../../../utils/data"
import {createGroup} from "../../../redux/group/groupAction"
import TermsOfSales from "../../../components/termsOfSales/termsOfSales"

const Payment = ({total}) => {
    const dispatch  = useDispatch()
    const data      = useSelector(({groupReducer}) => groupReducer)
    const {coordinator, stripe}  = useSelector(({groupReducer}) => groupReducer)

    const registration = (payment = false) => {
        dispatch(createGroup(data, payment === true ? 1 : 0))
    }

    return (
        <div className={"sr-card summary-block payment"}>
            <div className={"summary-header"}>
                Votre facture sera envoyée à l'issu du congrès.
            </div>
        {isDefined(coordinator) ? 
            <div className={"summary-header"}>
                <a className={"btn btn-sm btn-warning"} href={"https://demo.urevent.fr/documents/group_pro_forma/" + coordinator.group.idgroup} target="_blank">Télécharger la Proforma</a>
            </div>
            :
            <div className={"summary-header"}>
                Votre facture proforma sera disponible après l'enregistrement de votre inscription.
            </div>
        }
            <hr/>
            <div className={"summary-body"}>
                {isDefined(coordinator) ? isDefined(coordinator.group.group_payments) ?
                    parseFloat(coordinator.group.group_payments) < parseFloat(total) ?
                        <>
                            <div className={"row title-payment"}>
                                Paiement
                            </div>
                            <div className={"row"}>
                                <button className={"btn btn-warning"}><CreditCardModal stripe={stripe} total={total - (parseFloat(coordinator.group.group_payments) || 0)}/></button>
                            </div>
                            <div className={"row"}>
                                <button className={"btn btn-warning"}><BankTransferModal/></button>
                            </div>
                            <div className={"row"}>
                                Les virements sont autorisés jusqu'à 10 jours avant le congrès.
                            </div>
                        </>
                        :
                        parseFloat(coordinator.group.group_payments) > parseFloat(total) ?
                            <>
                                <div className={"row title-payment"}>
                                    Remboursement
                                </div>
                                <div className={"row"}>
                                    <button className={"btn btn-warning"}><RefundModal/></button>
                                </div>
                            </>
                            : "" : "" :
                    <>
                        <div className={"row title-payment"}>
                            Paiement
                        </div>
                        <div className={"row"}>
                            <button className={"btn btn-warning"}><CreditCardModal stripe={stripe} total={total}/></button>
                        </div>
                        <div className={"row"}>
                            <button className={"btn btn-warning"}><BankTransferModal/></button>
                        </div>
                        <div className={"row"}>
                            Les virements sont autorisés jusqu'à 10 jours avant le congrès.
                        </div>
                    </>}
            </div>
            <hr/>
            <div className={"summary-footer"}>
                <div className={"row"}>
                    <Link to={"confirmation-record"}>
                        <button className={"btn btn-warning"} onClick={() => registration()}>Enregistrer</button>
                    </Link>
                </div>
                <div className={"row"}>
                    <TermsOfSales />
                </div>
            </div>
        </div>
    )
}

Payment.propTypes = {
    total: PropTypes.string.isRequired,
}

export default Payment
