import React from "react"
import {useDispatch, useSelector} from "react-redux"

import {addMember} from "../../../../../redux/group/groupAction"

const Payment = ({onCloseModal}) => {
    const dispatch  = useDispatch()
    const data      = useSelector(({addMemberReducer}) => addMemberReducer)

    const recordRegistration = () => {
        dispatch(addMember(data))
        onCloseModal()
    }

    return (
        <div className={"sr-card summary-block payment"}>
            <div className={"summary-header"}>
            </div>
            <div className={"summary-footer"}>
                <div className={"row"}>
                    <button className={"btn btn-warning"} onClick={() => recordRegistration()}>Save member</button>
                </div>
            </div>
        </div>
    )
}

export default Payment
