import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import { FaIdCard } from 'react-icons/fa'

import {onChangeDocuments} from "../../../../../redux/addMember/addMemberAction"
import CountriesGeneralInput from "../../../../../components/form/countriesGeneralInput"
import {isDefined} from "../../../../../utils/data"

const InvitationLetterInput = () => {
    const dispatch      = useDispatch()
    const {documents}   = useSelector(({addMemberReducer}) => addMemberReducer)
    const {user} = useSelector(({apiReducer}) => apiReducer)

    const [visible, setVisible] = useState(false)
    const [linkIsVisible, setLinkIsVisible] = useState(false)

    useEffect(() => {
        setVisible(documents.registered_invit_letter.value === true)
    }, [documents.registered_invit_letter.value])

    useEffect(() => {
        const bool = documents.registered_invit_letter.value === true && documents.registered_birth.value.length > 0 && documents.registered_passportnb.value.length > 0 && documents.registered_passport_date.value.length > 0 && documents.registered_passport_nato.value.length > 1

            setLinkIsVisible(bool)
    }, [documents])

    const onChange = (e, checkbox = false) => {
        const name  = e.target.name
        const value = checkbox ? e.target.checked : e.target.value

        dispatch(onChangeDocuments(name, value))
    }

    return (
        <div className={"form-group"}> 
            <p className={"title"}>Invitation letter</p>
        <p className={"text-left"}>It is the delegate’s responsibility to investigate the <a href="http://www.exteriores.gob.es/Portal/en/ServiciosAlCiudadano/InformacionParaExtranjeros/Paginas/RequisitosDeEntrada.aspx" target="_blank">visa requirements for Spain</a> and apply for a visa, if necessary. 
Delegates requiring a letter of invitation, must have registered and paid for their registration. The deadline to request a letter of invitation is 12 October.
The letter of invitation does not oblige The Union to provide any financial support.  All expenses are the responsibility of the delegate.
</p>
            <div className={"form-row"}>
                <div className={"col-12 col-md-4 input-group"}>
                    <input type="checkbox" id={"invitationLetter"} className="form-control" name={"registered_invit_letter"} checked={documents.registered_invit_letter.value} value={documents.registered_invit_letter.value} onChange={e => onChange(e, true)} required/>
                    <label className={"label-checkbox"} htmlFor={"invitationLetter"}>Invitation letter ?</label>
                    {documents.registered_invit_letter.error !== "" ?
                        <div className="invalid-feedback">{documents.registered_invit_letter.error}</div>
                    : ""}
                </div>
            </div>
            <div className={"form-row"}>
                <div className={"col-12 col-md-6 input-group"} style={{display: visible ? "flex" : "none"}}>
                    <label htmlFor={"birthday"}>Date of birth</label>

                    <input type="date" id={"birthday"} className="form-control" name={"registered_birth"} placeholder={"Birthday"} value={documents.registered_birth.value} onChange={e => onChange(e)} required/>
                    {documents.registered_birth.error !== "" ?
                        <div className="invalid-feedback">{documents.registered_birth.error}</div>
                    : ""}
                </div>
                <div className={"col-12 col-md-6 input-group"} style={{display: visible ? "flex" : "none"}}>
                    <label htmlFor={"passportNumber"}>Passport number</label>
                    <input type="text" id={"registered_passportnb"} className="form-control" name={"registered_passportnb"} placeholder={"Passport number"} value={documents.registered_passportnb.value} onChange={e => onChange(e)} required/>
                    {documents.registered_passportnb.error !== "" ?
                        <div className="invalid-feedback">{documents.registered_passportnb.error}</div>
                    : ""}
                </div>
                <div className={"col-12 col-md-6 input-group"} style={{display: visible ? "flex" : "none"}}>
                    <label htmlFor={"passportValidity"}><FaIdCard/></label>
                    <input type="date" id={"passportValidity"} className="form-control" name={"registered_passport_date"} placeholder={"Passport validity"} value={documents.registered_passport_date.value} onChange={e => onChange(e)} required/>
                    {documents.registered_passport_date.error !== "" ?
                        <div className="invalid-feedback">{documents.registered_passport_date.error}</div>
                    : ""}
                </div>
                <div className={"col-12 col-md-6 input-group"} style={{display: visible ? "flex" : "none"}}>
                    <label htmlFor={"registered_passport_nato"}><FaIdCard/></label>
                    <CountriesGeneralInput name={"registered_passport_nato"}
                                           value={documents.registered_passport_nato.value}
                                           onChange={e => onChange(e)}
                                           placeholder={"Passport country"}
                                           required={false}/>
                    {documents.registered_passport_nato.error !== "" ?
                        <div className="invalid-feedback">{documents.registered_passport_nato.error}</div>
                    : ""}
                </div>
            </div>

            {linkIsVisible === true && isDefined(user) ? <div>Download PDF : <a href={"https://demo.urevent.fr/documents/invitation-letter/" + user.registered.idregistered} title={"Download PDF"} className={"download-pdf"}>https://demo.urevent.fr/documents/invitation-letter/{user.registered.idregistered}</a></div> : ""}

            {/*<button type="submit" onclick="window.open('file.doc')">*/}
        </div>
    )
}

export default InvitationLetterInput
