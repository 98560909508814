import React from "react"
import PropTypes from 'prop-types'
import {useDispatch} from "react-redux"

import {validTab} from "../../redux/tabs/tabsAction"

const About = ({nextTab}) => {
    const dispatch = useDispatch()

    const onNext = () => {
        dispatch(validTab("informationTab"))
        nextTab()
    }

    return (
        <div className={"content information"}>
            <p className={"title"}>
                Inscription pour le<br/>
                <strong>75e Congrès de l’Ordre des Experts-comptables</strong>
            </p>

            <p className={"important-infos"}>Informations importantes</p>

            <p className={"small"}>
                Toutes les commandes quelle que soit leur origine ne sont payables qu'en Euro. <br/>
                 Dès réception de votre commande et de votre règlement, vous recevrez une confirmation de commande par mail. <br/>
                 Vous pouvez réserver jusqu'au dernier moment sur Internet. <br/>
            </p>

            <p className={"small"}>
                Lorsque vous cliquez sur le bouton "Enregistrer" après le processus de commande, <br/>
                 vous déclarez accepter les conditions générales de vente.
            </p>


            <div className={"content-footer footer-alone"}>
                <button className={"sr-btn-next btn btn-info"} onClick={() => onNext()}>Suivant</button>
            </div>
        </div>
    )
}

About.propTypes = {
    nextTab: PropTypes.func.isRequired,
}

export default About
