import React from "react"
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from "react-redux"

import "../../../singleInformation/information.scss"
import CheckboxInput from "../../../../components/form/checkboxInput"
import {validTab} from "../../../../redux/tabs/tabsAction"
import AlertCategory from "../../alertCategory/alertCategory"
import {onChangeInformation} from "../../../../redux/addMember/addMemberAction"
import Tab from "../../../../components/Tab/Tab"

const Information = ({pageActive, index, onPrev, onNext}) => {
    const dispatch      = useDispatch()
    const {information} = useSelector(({addMemberReducer}) => addMemberReducer)
    const {registered_interest, registered_intouch} = information

    const onSubmit = (e = null) => {
        const form = e.currentTarget

        if(registered_interest.value.length > 0 && registered_intouch.value.length > 0) {
            dispatch(validTab("informationTab"))
            onNext()
        }
        else {

        }

        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        }

        //dispatch(submitInformation(user, values, onNext))
    }

    const getValue = (name, isInterest = true) => {
        if(isInterest)
            return registered_interest.value.indexOf(name) !== -1
        else
            return registered_intouch.value.indexOf(name) !== -1
    }

    return (
        <Tab pageActive={pageActive} index={index}>
        <div className={"content information"}>
            <AlertCategory/>

            <form onSubmit={onSubmit} className={"needs-validation"} noValidate>
                <div className="form-group">
                    <div className="form-row">
                        <div className={"col-12"}>
                            <p className={"title"}>Main areas of interest</p>

                            <div className="form-row">
                                <div className={"col-12 col-lg-6"}>
                                    <CheckboxInput placeholder={"Immunology"} onChange={e => dispatch(onChangeInformation(e))} name={"Immunology"} value={getValue("Immunology")}/>
                                    <CheckboxInput placeholder={"Drug and vaccine development"} onChange={e => dispatch(onChangeInformation(e))} name={"Drug and vaccine development"} value={getValue("Drug and vaccine development")}/>
                                    <CheckboxInput placeholder={"Diagnostics"} onChange={e => dispatch(onChangeInformation(e))} name={"Diagnostics"} value={getValue("Diagnostics")}/>
                                    <CheckboxInput placeholder={"Clinical Research"} onChange={e => dispatch(onChangeInformation(e))} name={"Clinical Research"} value={getValue("Clinical Research")}/>
                                    <CheckboxInput placeholder={"Clinical Care"} onChange={e => dispatch(onChangeInformation(e))} name={"Clinical Care"} value={getValue("Clinical Care")}/>
                                    <CheckboxInput placeholder={"Laboratory services"} onChange={e => dispatch(onChangeInformation(e))} name={"Laboratory services"} value={getValue("Laboratory services")}/>
                                </div>
                                <div className={"col-12 col-lg-6"}>
                                    <CheckboxInput placeholder={"Paediatrics"} onChange={e => dispatch(onChangeInformation(e))} name={"Paediatrics"} value={getValue("Paediatrics")}/>
                                    <CheckboxInput placeholder={"Epidemiology"} onChange={e => dispatch(onChangeInformation(e))} name={"Epidemiology"} value={getValue("Epidemiology")}/>
                                    <CheckboxInput placeholder={"HIV and other comorbidities"} onChange={e => dispatch(onChangeInformation(e))} name={"HIV and other comorbidities"} value={getValue("HIV and other comorbidities")}/>
                                    <CheckboxInput placeholder={"Tobacco control"} onChange={e => dispatch(onChangeInformation(e))} name={"Tobacco control"} value={getValue("Tobacco control")}/>
                                    <CheckboxInput placeholder={"Civil Society and Human rights"} onChange={e => dispatch(onChangeInformation(e))} name={"Civil Society and Human rights"} value={getValue("Civil Society and Human rights")}/>
                                </div>
                            </div>
                            {information.registered_interest.error !== "" ?
                                <div className="invalid-feedback">{information.registered_interest.error}</div>
                            : ""}
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className={"form-row"}>
                        <div className={"col-12"}>
                            <p className={"title"}>Keep in touch</p>
                            <p className={"small"}>
                                The Union would like to keep you updated about our work and the latest news, research and vital information on lung health. Please read our <a href="https://www.theunion.org/privacy-policy" target="_blank">Privacy Policy</a> and then tell us what you would like to hear about:
                            </p>

                            <div className="form-row">
                                <div className={"col-12 col-lg-6"}>
                                    <CheckboxInput placeholder={"Conferences and events"} onChange={e => dispatch(onChangeInformation(e, false))} name={"Conferences and events"} value={getValue("Conferences and events", false)}/>
                                    <CheckboxInput placeholder={"Union newsletter"} onChange={e => dispatch(onChangeInformation(e, false))} name={"Union newsletter"} value={getValue("Union newsletter", false)}/>
                                    <CheckboxInput placeholder={"Courses"} onChange={e => dispatch(onChangeInformation(e, false))} name={"Courses"} value={getValue("Courses", false)}/>
                                </div>
                                <div className={"col-12 col-lg-6"}>
                                    <CheckboxInput placeholder={"Membership"} onChange={e => dispatch(onChangeInformation(e, false))} name={"Membership"} value={getValue("Membership", false)}/>
                                    <CheckboxInput placeholder={"Centennial Dinner"} onChange={e => dispatch(onChangeInformation(e, false))} name={"Centennial Dinner"} value={getValue("Centennial Dinner", false)}/>  
                                    <CheckboxInput placeholder={"None of the above"} onChange={e => dispatch(onChangeInformation(e, false))} name={"None of the above"} value={getValue("None of the above", false)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div className={"content-footer"}>
                <button className={"sr-btn-next btn btn-secondary"} onClick={onPrev}>Previous</button>
                <button className={"sr-btn-next btn btn-info"} disabled={!(registered_interest.value.length > 0 && registered_intouch.value.length > 0)} onClick={onSubmit}>Next</button>
            </div>
        </div>
        </Tab>
    )
}

Information.propTypes = {
    onPrev: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
}

export default Information
