import React, {useEffect, useState} from "react"
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from "react-redux"

import "../../../singleInformation/documents/documents.scss"
import InvitationLetterInput from "./documents/invitationLetterInput"
import Upload from "../../../singleInformation/documents/upload"
import {
    submitDocuments,
    userToDocuments,
} from "../../../../redux/addMember/addMemberAction"
import {isDefined} from "../../../../utils/data"
import AlertCategory from "../../alertCategory/alertCategory"
import Tab from "../../../../components/Tab/Tab"

const Documents = ({pageActive, index, onPrev, onNext}) => {
    const dispatch      = useDispatch()
    const {documents}   = useSelector(({addMemberReducer}) => addMemberReducer)
    const {category}    = useSelector(({addMemberReducer}) => addMemberReducer)
    const {information} = useSelector(({addMemberReducer}) => addMemberReducer)

    const displayUpload = isDefined(category) ? category.idcategories === "8" || category.idcategories === "9" || category.idcategories === "16" || category.idcategories === "17" || category.idcategories === "18" || category.idcategories === "21" : false

    const [uploadIsVisible, setUploadIsVisible] = useState(displayUpload)

    useEffect(() => {
        setUploadIsVisible(displayUpload)
    }, [information.registered_profession_category, information.registered_under])

    const onSubmit = (e = null) => {
        e.preventDefault()
        
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation()
        }

        dispatch(submitDocuments(documents, onNext))
    }

    return (
        <Tab pageActive={pageActive} index={index}>
        <div className={"content documents"}>
            <AlertCategory/>

            <form onSubmit={onSubmit} className={"needs-validation"} noValidate>
                <InvitationLetterInput/>

                {uploadIsVisible === true ?
                    <div className={"form-group"}>
                        <Upload/>
                    </div>
                : ""}
                <div className={"content-footer"}>
                    <button type={"button"} className={"sr-btn-next btn btn-secondary"} onClick={() => onPrev()}>Previous</button>
                    <button type={"submit"} className={"sr-btn-next btn btn-info"}>Next</button>
                </div>
            </form>
        </div>
        </Tab>
    )
}

Documents.propTypes = {
    onPrev: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
}

export default Documents
