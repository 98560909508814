import React, {useEffect, useState} from "react"
import {useSelector} from "react-redux"

import "./alertCategory.scss"
import {isDefined} from "../../../utils/data"

const AlertCategory = () => {
    const {information} = useSelector(({registrationReducer}) => registrationReducer)
    const {category}    = useSelector(({registrationReducer}) => registrationReducer)

    const condition = isDefined(category) ? category.idcategories === "8" || category.idcategories === "9" || category.idcategories === "16" || category.idcategories === "17" || category.idcategories === "18" || category.idcategories === "21" : false
    const [alertIsVisible, setAlertIsVisible] = useState(condition)

    useEffect(() => {
        setAlertIsVisible(condition)
    }, [information.registered_profession_category, information.registered_under])

    return (
        alertIsVisible ?
            <div className={"alert-category"}>You are in a special category</div>
        : ""
    )
}

export default AlertCategory
