import React from "react"
import PropTypes from "prop-types"
import {useSelector} from "react-redux"

import "./summary/summary.scss"
import YourOrder from "./summary/your-order"
import Payment from "./summary/payment"
import {getTotal} from "../../../../utils/calculs"
import Tab from "../../../../components/Tab/Tab"

const Summary = ({pageActive, index, onPrev, onCloseModal}) => {
    const { ticket, options } = useSelector(({addMemberReducer}) => addMemberReducer)

    return (
        <Tab pageActive={pageActive} index={index}>
        <div className={"content summary"}>
            <p className={"title"}>Summary</p>

            <div className={"row"}>
                <div className={"col-lg-6"}>
                    <YourOrder ticket={ticket} options={options} total={getTotal(ticket, options)}/>
                </div>
                <div className={"col-lg-6"}>
                    <Payment onCloseModal={onCloseModal}/>
                </div>
            </div>

            <div className={"content-footer"}>
                <button className={"sr-btn-next btn btn-secondary"} onClick={() => onPrev()}>Previous</button>
            </div>
        </div>
        </Tab>
    )
}

Summary.propTypes = {
    onPrev: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
}

export default Summary


