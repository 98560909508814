import React from "react"
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import ReactLoading from 'react-loading'
import {useDispatch, useSelector} from "react-redux"

import "./stripe/stripe.scss"
import SRModal from "../../../components/modal/modal"
import CheckoutForm from "./stripe/checkoutForm"
import {resetStripe} from "../../../redux/registration/registrationAction"
import {createGroup} from "../../../redux/group/groupAction"
import {isDefined} from "../../../utils/data"

const CreditCardModal = ({stripe, total}) => {
    const dispatch  = useDispatch()
    const data      = useSelector(({groupReducer}) => groupReducer)

    const stripePromise = loadStripe(stripe.publishableKey)

    return (
        <SRModal text={"Carte de crédit"}
                 title={"Credit card"}
                 className={"creditCard addMember"}
                 onClose={() => dispatch(resetStripe())}
                 onOpen={() => dispatch(createGroup(data, 1))}>
            <p>
                Please enter your credit card details to complete your order. Your payment will be processed immediately
            </p>

            {isDefined(stripe.publishableKey) ?
                isDefined(stripe.clientSecret) ?
                    stripe.publishableKey.length > 0 && stripe.clientSecret.length > 0 ?
                <Elements stripe={stripePromise}>
                    <CheckoutForm clientSecret={stripe.clientSecret} total={total}/>
                </Elements>
            :
                <ReactLoading type={"spin"} color={"#00A7CC"} height={30} width={30} className={"sr-loading"} />
                : <ReactLoading type={"spin"} color={"#00A7CC"} height={30} width={30} className={"sr-loading"} />
                    : <ReactLoading type={"spin"} color={"#00A7CC"} height={30} width={30} className={"sr-loading"} />
            }
        </SRModal>
    )
}

export default CreditCardModal
