import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {useDispatch, useSelector} from "react-redux"

import RoundTicket from "../../../../components/roundTicket/roundTicket"
import {selectOptions} from "../../../../redux/addMember/addMemberAction"
import {validTab} from "../../../../redux/tabs/tabsAction"
import AlertCategory from "../../alertCategory/alertCategory"
import {isDefined} from "../../../../utils/data"
import Tab from "../../../../components/Tab/Tab"

const Options = ({pageActive, index, onPrev, onNext}) => {
    const dispatch          = useDispatch()
    const { optionsAPI, user } = useSelector(({apiReducer}) => apiReducer)
    const { options, onEdit }       = useSelector(({addMemberReducer}) => addMemberReducer)
    const [items, setItems] = useState([])

    const [optionsSelected, setOptionsSelected]     = useState(options)
    const [itemsSelected, setItemsSelected]         = useState(options.map(option => option.idoptions))
    const [optionsLimited, setOptionsLimited]       = useState(optionsSelected.filter(option => option.option_parent === "odt"))

    useEffect(() => {
        if((optionsSelected.length <= 0 && itemsSelected.length <= 0) || onEdit === true)
            setItems(optionsAPI)
    }, [optionsAPI])

    useEffect(() => {
        setOptionsLimited(optionsSelected.filter(option => option.option_parent === "odt"))
    }, [optionsSelected])

    useEffect(() => {
        setOptionsSelected(options)
        setItemsSelected(options.map(option => option.idoptions))
    }, [options])
    
    const select = option => {
        const index = itemsSelected.indexOf(option.idoptions)

        if(index !== -1) {
            const array = [...itemsSelected]
            const prevOptionsSelected = [...optionsSelected]
            array.splice(index, 1)
            setItemsSelected(array)
            prevOptionsSelected.splice(index, 1)
            setOptionsSelected(prevOptionsSelected)
            dispatch(selectOptions(option, false))
        }
        else {
            if(option.option_parent !== "odt" || (optionsLimited.length < 2 && option.option_parent === "odt" )) {
                dispatch(selectOptions(option))
                setItemsSelected(prevItems => prevItems.concat(option.idoptions))
                setOptionsSelected(prevItems => prevItems.concat(option))
            }
        }
    }

    const onClickNext = (e) => {
        e.preventDefault()
        dispatch(validTab("optionsTab"))
        onNext()
    }

    const readOnly = option => onEdit === true ? options.find(obj => obj.idoptions === option.idoptions) !== undefined : false

    return (
        <Tab pageActive={pageActive} index={index}>
        <div className={"content options"}>
            <AlertCategory/>
        <p className={"title"}>Select your option(s)</p>
            <form>
                <div className={"row row-options"}>
                    {items.map((item, i) =>
                        parseInt(item.options_price) > 0 && item.included !== 1 ?
                            <RoundTicket key={i}
                                     name={item.options_name}
                                     price={item.options_price}
                                     isSelected={itemsSelected.find(obj => obj === item.idoptions) !== undefined}
                                     onClick={() => select(item)}
                                     isOption={true}
                                     readOnly={item.included === 1 || readOnly(item)}
                                     isLimited={optionsLimited.length >= 2 && item.option_parent === "odt"}
                            />
                        : ""
                     )}
                </div>
            </form>

            <div className={"content-footer"}>
                <button className={"sr-btn-next btn btn-secondary"} onClick={onPrev}>Previous</button>
                <button className={"sr-btn-next btn btn-info"} onClick={onClickNext}>Next</button>
            </div>
        </div>
        </Tab>
    )
}

Options.propTypes = {
    onPrev: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
}

export default Options
