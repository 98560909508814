import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {useDispatch, useSelector} from "react-redux"

import {isDefined} from "../../../../utils/data"
import Tab from "../../../../components/Tab/Tab"
import {selectTicket} from "../../../../redux/addMember/addMemberAction"
import {getOptionsFormTicket} from "../../../../redux/api/apiAction"
import {validTab} from "../../../../redux/tabs/tabsAction"
import RoundTicket from "../../../../components/roundTicket/roundTicket"
import AlertCategory from "../../alertCategory/alertCategory"

const Ticket = ({pageActive, index, onPrev, onNext}) => {
    const dispatch = useDispatch()
    const { category, ticket, onEdit }    = useSelector(({addMemberReducer}) => addMemberReducer)
    const [items, setItems] = useState([])
    const [item, setItem]   = useState(null)

    const disabledInput = isDefined(onEdit) ? onEdit : false

    useEffect(() => {
        if(category !== null)
            setItems(category.package)
    }, [category])

    const [itemSelected, setItemSelected] = useState(ticket !== null ? ticket.idpackages : null)
    
     useEffect(() => {
            setItemSelected(ticket !== null ? ticket.idpackages : null)
        setItem(ticket)
        }, [ticket])

    const selectItem = (ticketSelected) => {
        if(ticketSelected !== undefined)
            if(ticketSelected !== null) {
                setItemSelected(ticketSelected.idpackages)
                setItem(ticketSelected)
                dispatch(selectTicket(ticketSelected))
                dispatch(getOptionsFormTicket(category.idcategories, ticketSelected.idpackages))
                dispatch(validTab("optionsTab"))
            }
    }

    const onClickNext = () => {
        dispatch(getOptionsFormTicket(category.idcategories, item.idpackages))
        dispatch(validTab("optionsTab"))
        onNext()
    }
    
    return (
        <Tab pageActive={pageActive} index={index}>
        <div className={"content tickets"}>
            <AlertCategory/>

            <p className={"title txt-center"}>Choose your ticket</p>
            <p className={"important-infos  txt-center"}>Please select the type of ticket you wish to purchase.<br/>Note: When selecting ‘Standard’ or ‘One day ticket’ you will be able to add TBScience at the next step.</p>

            <form>
                <div className={"row row-tickets"}>
                    {disabledInput && ticket !== null ?
                        <RoundTicket name={ticket.packages_name}
                                     price={ticket.packages_price}
                                     description={ticket.packages_desc}
                                     isSelected={ticket.idpackages === itemSelected}
                                     onClick={() => {}}/>
                        :
                        items.map((item, i) =>
                            <RoundTicket key={i}
                                         name={item.packages_name}
                                         price={item.packages_price}
                                         description={item.packages_desc}
                                         isSelected={item.idpackages === itemSelected}
                                         onClick={() => selectItem(item)}
                            />
                        )}
                </div>
            </form>

            <div className={"content-footer"}>
                <button className={"sr-btn-next btn btn-secondary"} onClick={() => onPrev()}>Previous</button>
                <button className={"sr-btn-next btn btn-info"} disabled={itemSelected === null} onClick={() => onClickNext()}>Next</button>
            </div>
        </div>
        </Tab>
    )
}

Ticket.propTypes = {
    pageActive: PropTypes.number.isRequired,
    index:  PropTypes.number.isRequired,
    onPrev: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
}

export default Ticket
